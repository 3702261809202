@import "../../styles/variables";

.link {
  color: black;
  font-weight: 700;
  font-size: $large;
  border-bottom: 2px solid transparent;
  transition: border-bottom 0.5s;
  cursor: pointer;

  @media only screen and (min-width: $bp-desktop) {
    color: white;
    font-weight: 400;

    &:hover {
      border-bottom: 2px solid white;
      transition: border-bottom 0.2s;
    }
  }
}
