@import "../../styles/components";

.list {
  padding-left: 1.2em;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.company {
  display: flex;
  align-items: center;
  gap: 12px;
}
