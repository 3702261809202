@import "../../styles/variables";
@import "../../styles/animations";
@import "../../styles/layout";
@import "../../styles/components";

.container {
  padding-bottom: 0;
  margin-top: 100px;
  overflow-y: hidden;

  @media only screen and (min-width: $bp-desktop) {
    margin-top: 0px;
  }
}

.links {
  margin-bottom: 32px;
}
