// Breakpoints
$bp-tablet: 768px;
$bp-desktop: 1024px;

// Colors
$blue: rgb(115, 158, 223);
$gray: rgb(238, 238, 238);
$black: black;

// Font sizes
$small: 14px;
$large: 20px;
