@import "../../styles/variables";
@import "../../styles/animations";
@import "../../styles/layout";
@import "../../styles/components";

.container {
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: $blue;
  color: white;
  position: sticky;
  top: 0;
  z-index: 9999;
  font-size: $large;
}

.innerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;

  @media only screen and (min-width: $bp-desktop) {
    height: 60px;
  }
}

.desktopLinks {
  display: flex;
  gap: 32px;
}

.mobileLinks {
  @extend .container;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  background-color: $gray;
  gap: 40px;
  position: fixed;
  top: 64px;
  z-index: 2;
  min-height: calc(100vh - 64px);
  width: 100%;
}
