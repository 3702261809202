@import "./variables";

.icon {
  height: 40px;
}

.date {
  color: grey;
  margin: 0;
  font-weight: 100;
}

.links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.link {
  color: $black;
  text-decoration: None;
  border-bottom: 2px solid $black;
  padding-bottom: 2px;
  transition: border-bottom 0.5s, padding-bottom 0.5s;
  cursor: pointer;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      border-bottom: 2px solid transparent;
      padding-bottom: 8px;
      transition: border-bottom 0.2s, padding-bottom 0.2s;
    }
  }
}
