@import "../../styles/variables";
@import "../../styles/animations";
@import "../../styles/layout";

.experiences {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.verticalTimeline {
  margin-left: 24px;
}

.starIcon {
  margin: auto;
}
