@import "../../styles/variables";
@import "../../styles/animations";
@import "../../styles/layout";

.projects {
  display: flex;
  flex-direction: column;
  gap: 80px;

  @media only screen and (min-width: $bp-desktop) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
    gap: 36px;
  }
}
