@import "../../styles/animations";
@import "../../styles/variables";
@import "../../styles/components";

.icons {
  display: flex;
  gap: 12px 24px;
  flex-wrap: wrap;
  margin-top: 12px;

  @media only screen and (min-width: $bp-tablet) {
    margin-top: 24px;
  }

  @media only screen and (min-width: $bp-desktop) {
    gap: 12px 36px;
  }
}

.iconWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;

  &:hover > .iconName {
    transition: opacity 0.8s;
    opacity: 1;
  }
}

.iconName {
  @extend .fadeHidden;
  margin: 0 -1000px;
  font-size: $small;
}
