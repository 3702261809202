@import "../../styles/variables.scss";

.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  background-color: $blue;
  color: white;

  @media only screen and (min-width: $bp-desktop) {
    flex-direction: row-reverse;
    font-size: 1.3em;
    gap: 100px;
  }
}

.text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  @media only screen and (min-width: $bp-desktop) {
    align-items: flex-start;
  }
}

.profilePic {
  height: 200px;
  width: 200px;
  border-radius: 50%;
  border: 4px solid white;

  @media only screen and (min-width: $bp-desktop) {
    height: 350px;
    width: 350px;
  }
}

.name {
  font-size: 2em;
  font-weight: bold;
}
