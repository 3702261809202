@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import "./variables";

html {
  color: rgb(99, 99, 99);
  font-size: 16px;
  font-family: "Poppins";

  @media only screen and (min-width: $bp-desktop) {
    font-size: 18px;
  }
}

body {
  margin: 0;
  overflow-x: hidden;
}

p {
  margin: 0;
  text-align: justify;
}

h2 {
  @media only screen and (min-width: $bp-desktop) {
    margin: 32px 0px;
  }
}

h3 {
  margin: 16px 0;
}
