@import "../../styles/variables";
@import "../../styles/animations";
@import "../../styles/components";

.project {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.screenshot {
  object-fit: cover;
  width: 100%;
}

.date {
  margin-top: 12px;

  @media only screen and (min-width: $bp-desktop) {
    margin-left: auto;
    margin-right: auto;
  }
}

.title {
  color: $black;
  @media only screen and (min-width: $bp-desktop) {
    margin-left: auto;
    margin-right: auto;
  }
}
